




























 
 import {
     Component,
     Vue,
 } from 'vue-property-decorator';
 
 import {
     User
 } from '@/store/user'
 
 import {
     Core
 } from '@/store/core'
 import _ from 'lodash'
 @Component({
     components: {
          
     },
     computed: {

     }
 })
 export default class BuffMenu extends Vue {
 
     farm:any = User.farm;
    data:any = {}
     async created(){
         this.data = await Core.getHttp(`/user/buffalo/count/user/${this.farm.id}/`)
     }


}
