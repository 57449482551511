





















































































































import BuffMenu from "@/components/Report/Menu.vue";

import { Component, Vue } from "vue-property-decorator";

import { mapState } from "vuex";

import { User } from "@/store/user";

import { Geography } from "@/models/core";
import { Core } from "@/store/core";
import _ from "lodash";
@Component({
  components: {
    BuffMenu,
  },
  computed: {},
})
export default class Home extends Vue {
  user: any = User.user;
  profile: any = User.profile;

  width: any = this.$vuetify.breakpoint.width;

  async created() {}

  // get user(){
  //     return User.user
  // }

  //  get user(){
  //     return User.profile
  // }
}
